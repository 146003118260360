:root {
  --app-modal-button-text-color: var(--f7-theme-color);
  --app-modal-button-text-align: center;
  --app-modal-width: 320px;
}

:root .dark,
:root.dark {
  --app-modal-text-color: #fff;
}

.ios {
  --app-modal-box-shadow: none;
  --app-modal-inner-padding: 16px;
  --app-modal-border-radius: 13px;
  --app-modal-text-align: center;
  --app-modal-font-size: 14px;
  --app-modal-title-text-color: inherit;
  --app-modal-title-font-size: 18px;
  --app-modal-title-font-weight: 600;
  --app-modal-title-line-height: inherit;
  --app-modal-button-font-size: 17px;
  --app-modal-button-height: 44px;
  --app-modal-button-letter-spacing: 0;
  --app-modal-button-font-weight: 400;
  --app-modal-button-text-transform: none;
  --app-modal-preloader-size: 34px;
  --app-modal-bg-color: rgba(255, 255, 255, 0.95);
  --app-modal-bg-color-rgb: 255, 255, 255;
  --app-modal-text-color: #000;
  --app-modal-button-pressed-bg-color: rgba(0, 0, 0, 0.1);
  --app-modal-border-divider-color: rgba(0, 0, 0, 0.2);
}

.ios .dark,
.ios.dark {
  --app-modal-bg-color: rgba(45, 45, 45, 0.95);
  --app-modal-bg-color-rgb: 45, 45, 45;
  --app-modal-button-pressed-bg-color: rgba(0, 0, 0, 0.2);
  --app-modal-border-divider-color: rgba(255, 255, 255, 0.15);
}

.md {
  --app-modal-box-shadow: var(--f7-elevation-24);
  --app-modal-inner-padding: 24px;
  --app-modal-border-radius: 4px;
  --app-modal-text-align: left;
  --app-modal-font-size: 16px;
  --app-modal-title-font-size: 20px;
  --app-modal-title-font-weight: 500;
  --app-modal-title-line-height: 1.3;
  --app-modal-button-font-size: 14px;
  --app-modal-button-height: 36px;
  --app-modal-button-letter-spacing: 0.05em;
  --app-modal-button-font-weight: 500;
  --app-modal-button-text-transform: uppercase;
  --app-modal-button-pressed-bg-color: rgba(var(--f7-theme-color-rgb), 0.15);
  --app-modal-preloader-size: 32px;
  --app-modal-bg-color: #fff;
  --app-modal-text-color: rgba(0, 0, 0, 0.54);
  --app-modal-title-text-color: #212121;
}

.md .dark,
.md.dark {
  --app-modal-bg-color: #1c1c1d;
  --app-modal-title-text-color: #fff;
}

.aurora {
  --app-modal-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15), 0 25px 30px 0 rgba(0, 0, 0, 0.35);
  --app-modal-inner-padding: 20px;
  --app-modal-border-radius: 8px;
  --app-modal-text-align: left;
  --app-modal-font-size: 14px;
  --app-modal-title-font-size: 18px;
  --app-modal-title-font-weight: 700;
  --app-modal-title-line-height: inherit;
  --app-modal-button-font-size: 14px;
  --app-modal-button-height: 36px;
  --app-modal-button-letter-spacing: 0;
  --app-modal-button-font-weight: 600;
  --app-modal-button-text-transform: uppercase;
  --app-modal-preloader-size: 24px;
  --app-modal-bg-color: #fff;
  --app-modal-title-text-color: inherit;
  --app-modal-text-color: #000;
  --app-modal-button-text-color: #fff;
}

.aurora .dark,
.aurora.dark {
  --app-modal-bg-color: #1c1c1d;
  --app-modal-title-text-color: #fff;
}

.modal {
  position: absolute;
  z-index: 13500;
  left: 50%;
  margin-top: 0;
  top: 50%;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0, -50%, 0) scale(1.185);
  transition-property: transform, opacity;
  display: none;
  transition-duration: 0.4s;
  box-shadow: var(--app-modal-box-shadow);
  width: var(--app-modal-width);
  margin-left: calc(-1 * var(--app-modal-width) / 2);
  border-radius: var(--app-modal-border-radius);
  text-align: var(--app-modal-text-align);
  color: var(--app-modal-text-color);
  font-size: var(--app-modal-font-size);
  background: var(--app-modal-bg-color);
  will-change: transform, opacity;
}

.modal-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 13500;
  visibility: hidden;
  opacity: 0;
  transition-duration: 400ms;
}

.modal-backdrop.not-animated {
  transition-duration: 0ms;
}

.modal-backdrop.backdrop-in {
  visibility: visible;
  opacity: 1;
}

.modal.modal-fluid {
  --app-modal-width: calc(100% - 32px);
  max-width: 518px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .ios-translucent-modals .modal {
    background-color: rgba(var(--app-modal-bg-color-rgb), 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
}

.modal .modal-close {
  position: absolute;
  top: var(--app-modal-inner-padding);
  right: var(--app-modal-inner-padding);
}

.modal.modal-in {
  opacity: 1;
  transform: translate3d(0, -50%, 0) scale(1);
}

.modal.modal-out {
  opacity: 0;
  z-index: 13499;
}

.modal.not-animated {
  transition-duration: 0s;
}

.modal-inner {
  position: relative;
  padding: var(--app-modal-inner-padding);
}

.modal-title {
  color: var(--app-modal-title-text-color);
  font-size: var(--app-modal-title-font-size);
  font-weight: var(--app-modal-title-font-weight);
  line-height: var(--app-modal-title-line-height);
}

.modal-buttons {
  position: relative;
  display: flex;
}

.modal-buttons-vertical .modal-buttons {
  display: block;
  height: auto !important;
}

.modal-button {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--app-modal-button-text-color);
  font-size: var(--app-modal-button-font-size);
  height: var(--app-modal-button-height);
  line-height: var(--app-modal-button-height);
  letter-spacing: var(--app-modal-button-letter-spacing);
  text-align: var(--app-modal-button-text-align);
  font-weight: var(--app-modal-button-font-weight);
  text-transform: var(--app-modal-button-text-transform);
  display: block;
  cursor: pointer;
}

.modal-button.active-state {
  background-color: var(--app-modal-button-pressed-bg-color);
}

.modal-no-buttons .modal-buttons {
  display: none;
}

.modal-preloader .preloader {
  --f7-preloader-size: var(--app-modal-preloader-size);
}

html.with-modal .page-content {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

.ios .modal.modal-out {
  transform: translate3d(0, -50%, 0) scale(1);
}

.ios .modal-inner {
  border-radius: var(--app-modal-border-radius) var(--app-modal-border-radius) 0 0;
}

.ios .modal-inner:after {
  content: "";
  position: absolute;
  background-color: var(--app-modal-border-divider-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}

.ios .modal-title + .modal-text {
  margin-top: 5px;
}

.ios .modal-buttons {
  height: 44px;
  justify-content: center;
}

.ios .modal-button {
  width: 100%;
  padding: 0 5px;
  flex: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
}

.ios .modal-button:after {
  content: "";
  position: absolute;
  background-color: var(--app-modal-border-divider-color);
  display: block;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  width: 1px;
  height: 100%;
  transform-origin: 100% 50%;
  transform: scaleX(calc(1 / var(--f7-device-pixel-ratio)));
}

.ios .modal-button:first-child {
  border-radius: 0 0 0 var(--app-modal-border-radius);
}

.ios .modal-button:last-child {
  border-radius: 0 0 var(--app-modal-border-radius) 0;
}

.ios .modal-button:last-child:after {
  display: none !important;
}

.ios .modal-button:first-child:last-child {
  border-radius: 0 0 var(--app-modal-border-radius) var(--app-modal-border-radius);
}

.ios .modal-button.modal-button-bold {
  font-weight: 500;
}

.ios .modal-button[class*="color-"] {
  --app-modal-button-text-color: var(--f7-theme-color);
}

.ios .modal-buttons-vertical .modal-buttons {
  height: auto;
}

.ios .modal-buttons-vertical .modal-button {
  border-radius: 0;
}

.ios .modal-buttons-vertical .modal-button:after {
  content: "";
  position: absolute;
  background-color: var(--app-modal-border-divider-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}

.ios .modal-buttons-vertical .modal-button:last-child {
  border-radius: 0 0 var(--app-modal-border-radius) var(--app-modal-border-radius);
}

.ios .modal-buttons-vertical .modal-button:last-child:after {
  display: none !important;
}

.ios .modal-no-buttons .modal-inner {
  border-radius: var(--app-modal-border-radius);
}

.ios .modal-no-buttons .modal-inner:after {
  display: none !important;
}

.ios .modal-preloader .modal-text ~ .preloader,
.ios .modal-preloader .modal-title ~ .preloader {
  margin-top: 15px;
}

.ios .modal-progress .modal-text ~ .progressbar,
.ios .modal-progress .modal-text ~ .progressbar-infinite,
.ios .modal-progress .modal-title ~ .progressbar,
.ios .modal-progress .modal-title ~ .progressbar-infinite {
  margin-top: 15px;
}

.md .modal.modal-out {
  transform: translate3d(0, -50%, 0) scale(0.815);
}

.md .modal-title + .modal-text {
  margin-top: 20px;
}

.md .modal-text {
  line-height: 1.5;
}

.md .modal-buttons {
  height: 48px;
  padding: 6px 8px;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: flex-end;
}

.md .modal-button {
  --f7-touch-ripple-color: rgba(var(--f7-theme-color-rgb), 0.25);
  border-radius: 4px;
  min-width: 64px;
  padding: 0 8px;
  border: none;
  transition-duration: 0.3s;
  transform: translate3d(0, 0, 0);
}

.md .modal-button.modal-button-bold {
  font-weight: 700;
}

.md .modal-button + .modal-button {
  margin-left: 4px;
}

.md .modal-button[class*="color-"] {
  --app-modal-button-text-color: var(--f7-theme-color);
}

.md .modal-buttons-vertical .modal-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.md .modal-buttons-vertical .modal-button + .modal-button {
  margin-top: 12px;
  margin-left: 0;
}

.md .modal-preloader .modal-inner,
.md .modal-preloader .modal-title,
.md .modal-progress .modal-inner,
.md .modal-progress .modal-title {
  text-align: center;
}

.md .modal-preloader .modal-text ~ .preloader,
.md .modal-preloader .modal-title ~ .preloader {
  margin-top: 20px;
}

.md .modal-progress .modal-text ~ .progressbar,
.md .modal-progress .modal-text ~ .progressbar-infinite,
.md .modal-progress .modal-title ~ .progressbar,
.md .modal-progress .modal-title ~ .progressbar-infinite {
  margin-top: 16px;
}

.aurora .modal.modal-out {
  transform: translate3d(0, -50%, 0) scale(0.815);
}

.aurora .modal-title + .modal-text {
  margin-top: 16px;
}

.aurora .modal-text {
  line-height: 1.5;
}

.aurora .modal-buttons {
  padding: var(--app-modal-inner-padding);
  padding-top: 0;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: flex-end;
}

.aurora .modal-button {
  border-radius: 8px;
  min-width: 64px;
  padding: 0 10px;
  border: none;
  transition-duration: 0.3s;
  transform: translate3d(0, 0, 0);
  background: var(--f7-theme-color);
}

.aurora .modal-button.modal-button-bold {
  font-weight: 600;
}

.aurora .modal-button + .modal-button {
  margin-left: 16px;
}

.aurora .modal-button.active-state {
  background-color: var(--app-modal-button-pressed-bg-color, var(--f7-theme-color-shade));
}

.aurora.device-desktop .modal-button:not(.active-state):not(.no-hover):hover {
  background-color: var(--app-modal-button-hover-bg-color, var(--f7-theme-color-tint));
}

.aurora .modal-buttons-vertical .modal-buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.aurora .modal-buttons-vertical .modal-button {
  margin-left: 0;
  flex-shrink: 0;
}

.aurora .modal-buttons-vertical .modal-button + .modal-button {
  margin-top: 8px;
}

.aurora .modal-preloader .modal-inner,
.aurora .modal-preloader .modal-title,
.aurora .modal-progress .modal-inner,
.aurora .modal-progress .modal-title {
  text-align: center;
}

.aurora .modal-preloader .modal-text ~ .preloader,
.aurora .modal-preloader .modal-title ~ .preloader {
  margin-top: 16px;
}

.aurora .modal-progress .modal-text ~ .progressbar,
.aurora .modal-progress .modal-text ~ .progressbar-infinite,
.aurora .modal-progress .modal-title ~ .progressbar,
.aurora .modal-progress .modal-title ~ .progressbar-infinite {
  margin-top: 16px;
}
