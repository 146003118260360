body {
  background: #ccc;
}

:root {
  --f7-page-bg-color: #efeff4;
  --f7-list-item-media-margin: 8px;
  --f7-card-header-font-weight: 600;
  --f7-block-title-white-space: normal;
  --f7-theme-color-divider-bg-color: #c0c0c0;
  --f7-list-margin-vertical: 24px;
  --app-cast-item-border-width: 0.2rem;
  --app-cast-item-after-background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

:root .dark {
  --f7-navbar-text-color: #c97d0b;
  --f7-theme-color-text-color: #c97d0b;
  --f7-theme-color: #a60bc9;
  --f7-theme-color-rgb: 166, 11, 201;
  --f7-theme-color-shade: #c39248;
  --f7-theme-color-tint: #86550b;
  --f7-theme-color-divider-bg-color: #2a1e02;
  --f7-navbar-bg-color: rgba(0, 0, 0, 0.75);
  --f7-bars-bg-image: linear-gradient(45deg, #111, #222, #333, #000);
  --f7-list-item-border-color: rgba(255, 255, 255, 0.5);
  --f7-login-screen-content-bg-color: transparent;
  --f7-list-item-after-text-color: #3eb4c9;
}

.dark .page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("./assets/image/bg-dark.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -2;
}

.dark .page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: -1;
}

.dark .popup .page::before,
.dark .popup .page::after {
  content: unset;
}

.ios {
  --f7-toolbar-height: 56px;
  --f7-button-height: 36px;
  --f7-toolbar-font-size: 14px;
}

.ios .message + .message:not(.message-first) {
  margin-top: 4px;
}

.item-input-outline .item-input-info,
.item-input-outline .item-input-error-message {
  padding: 0;
}

.block.inset .list .item-content {
  padding-left: 0;
}

.block.inset .list .item-inner {
  padding-right: 0;
}

.text-align-middle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#framework7-root {
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  background: #fff;
}

.list.list-item-no-padding {
  --f7-list-item-padding-horizontal: 0;
}

.list.list-bg-transparent {
  --f7-list-bg-color: transparent;
}

.list .list-item-after-wrap .item-after {
  white-space: pre-line;
  word-break: break-all;
  max-width: calc(100vw - 140px);
}

@media (min-width: 540px) {
  .list .list-item-after-wrap .item-after {
    max-width: 400px;
  }
}

.list.list-no-divider ul:before,
.list.list-no-divider ul:after,
.list.list-no-divider .item-inner:before,
.list.list-no-divider .item-inner:after {
  content: unset;
}

.list.list-inner-wrap .item-inner {
  flex-wrap: wrap;
}

.list.media-list.media-centered .item-media {
  align-self: center;
}

.item-input-outline .item-label {
  left: 0;
  margin-bottom: 4px;
  font-size: 14px;
}

.item-input-wrap {
  margin-bottom: 4px;
}

.item-input-wrap .item-input-error-message {
  white-space: inherit;
}

.tabbar.highlight-bottom .tab-link-highlight {
  bottom: 0;
}

.tabbar.highlight-top .tab-link-highlight {
  top: 0;
}

.dialog-backdrop.backdrop-alert {
  z-index: 999998;
}

.dialog.dialog-alert {
  z-index: 999999;
}

textarea.resizable {
  overflow-y: hidden;
}

.popup:not(.popup-tablet-fullscreen) {
  --f7-popup-tablet-width: 100%;
}

/**
 * menu-item-dropdown
 */
.menu-item.menu-item-dropdown[class*="bg-color-"] {
  --f7-menu-bg-color: var(--f7-theme-color-bg-color);
}

/**
 * ptr-refreshing
 */
.md .ptr-bottom.ptr-refreshing .ptr-preloader {
  transform: translate3d(0, -100px, 0);
}

/**
 * navbar
 */
.md .navbar-inner {
  justify-content: space-between;
  overflow: visible;
}

/**
 * picker
 */
.picker-column {
  min-width: 48px;
}

.picker-column.picker-column-divider {
  width: auto;
  min-width: 24px;
}

.picker-column.picker-column-center {
  justify-content: center;
}

/**
 * item-input
 */
.md .item-input .item-media {
  align-self: flex-start;
}

/**
 * sheet
 */
.sheet-backdrop {
  z-index: 14000;
}

.sheet-modal {
  z-index: 15500;
}

/**
 * popover
 */
.popover-backdrop {
  z-index: 14000;
}

.popover {
  z-index: 15500;
}

.bordered {
  border: 1px solid var(--f7-list-item-border-color);
}

/** bg-color-theme */
.bg-color-error {
  --f7-theme-color-bg-color: #f8d7da;
}

.bg-color-warning {
  --f7-theme-color-bg-color: #d39014;
}

.bg-color-success {
  --f7-theme-color-bg-color: #edf6ea;
}

.bg-color-info {
  --f7-theme-color-bg-color: #192c3d;
}

.bg-color-page {
  --f7-theme-color-bg-color: var(--f7-page-bg-color);
}

/** text-color-theme */
.text-color-error {
  --f7-theme-color-text-color: #842029;
}

.text-color-warning {
  --f7-theme-color-text-color: #e9a53e;
}

.text-color-success {
  --f7-theme-color-text-color: #4b9b33;
}

.text-color-info {
  --f7-theme-color-text-color: #c0d6ee;
}

/** border-color-theme */
.border-color-error {
  --f7-theme-color-border-color: #f5c2c7;
}

.border-color-warning {
  --f7-theme-color-border-color: #614208;
}

.border-color-success {
  --f7-theme-color-border-color: #b3cfa8;
}

.border-color-info {
  --f7-theme-color-border-color: #104b88;
}

.fluid {
  width: 100% !important;
}

.fab.fab-bg-transparent > a {
  background: transparent !important;
}

.actions-modal-fluid {
  width: 100%;
  left: 0;
  margin: 0;
}
