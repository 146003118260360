.cropper-circle .cropper-crop-box,
.cropper-circle .cropper-view-box {
  border-radius: 50%;
}

.cropper-circle .cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-circle .cropper-face {
  background-color: inherit !important;
}

.cropper-circle .cropper-dashed,
.cropper-circle .cropper-point.point-se,
.cropper-circle .cropper-point.point-sw,
.cropper-circle .cropper-point.point-nw,
.cropper-circle .cropper-point.point-ne,
.cropper-circle .cropper-line {
  display: none !important;
}

.cropper-circle .cropper-view-box {
  outline: inherit !important;
}